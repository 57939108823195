import { render, staticRenderFns } from "./EditTeamsSidebarTeamCard.vue?vue&type=template&id=4f53c776&scoped=true&"
import script from "./EditTeamsSidebarTeamCard.vue?vue&type=script&lang=ts&"
export * from "./EditTeamsSidebarTeamCard.vue?vue&type=script&lang=ts&"
import style0 from "./EditTeamsSidebarTeamCard.vue?vue&type=style&index=0&id=4f53c776&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f53c776",
  null
  
)

export default component.exports