import { render, staticRenderFns } from "./UserSearchWrapper.vue?vue&type=template&id=dc17b198&scoped=true&"
import script from "./UserSearchWrapper.vue?vue&type=script&lang=ts&"
export * from "./UserSearchWrapper.vue?vue&type=script&lang=ts&"
import style0 from "./UserSearchWrapper.vue?vue&type=style&index=0&id=dc17b198&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc17b198",
  null
  
)

export default component.exports