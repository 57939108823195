import { render, staticRenderFns } from "./PermissionTag.vue?vue&type=template&id=4456d16a&scoped=true&"
import script from "./PermissionTag.vue?vue&type=script&lang=ts&"
export * from "./PermissionTag.vue?vue&type=script&lang=ts&"
import style0 from "./PermissionTag.vue?vue&type=style&index=0&id=4456d16a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4456d16a",
  null
  
)

export default component.exports